













































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
@Component({
  components: {}
})
export default class apple extends Vue {
  private downUrl = "";//this.config.appleLink;
  private appName = "";
  private logo = "";
  private SwiperOptions = {
    speed: 500,
    slidesPerView: "auto",
    paginationClickable: true,
    spaceBetween: 20
  };
  private link = location.origin
  private showtext = false;
  created() {
    window.scrollTo(0, 0)
    let self: any = this;

    let link:any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = require('@/assets/applestore/apple.png');
    document.getElementsByTagName('head')[0].appendChild(link);

    // for (const key in self.config.appleImage) {
    //   self.config.appleImage[key] = require("../../assets/applestore/" +
    //     self.config.appleImage[key] +
    //     ".png");
    // }

    // var language;
    // language = navigator.language;
    // switch (language) {
    //   case "zh-CN":
    //     this.$i18n.locale = "zh";
    //     break; //"中文(简体)"
    //   case "zh-TW":
    //     this.$i18n.locale = "tw";
    //     break; //"中文(繁体)" 

    //   default:
    //     this.$i18n.locale = "en";
    //     break;
    // }
    axios.get("/client/api/getVsersion?type=2").then(res => {
        self.downUrl = res.data.data.downloadUrl;
    });
    axios.get("/client/api/findConfigByKey?configKey=h5_logo_url").then(res => {
      self.logo = res.data.data.configValue;
    });
    axios.get("/client/api/findConfigByKey?configKey=platform").then(res => {
      self.appName = res.data.data.configValue;
      document.title = self.appName;
    });
  }
}
